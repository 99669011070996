import * as THREE from 'three'
import { Canvas, useLoader } from '@react-three/fiber'
import { MeshTransmissionMaterial, useGLTF, AccumulativeShadows, RandomizedLight, Environment, OrbitControls, Center } from '@react-three/drei'
import { useControls } from 'leva'

export default function App() {
  return (
    <Canvas shadows camera={{ position: [15, 0, 15], fov: 25 }}>
      <ambientLight intensity={Math.PI} />
      <group position={[0, -2.5, 0]}>
        <Center top>
          <GelatinousCube />
        </Center>
        <AccumulativeShadows temporal frames={100} alphaTest={0.9} color="#818e96" colorBlend={1} opacity={0.8} scale={20}>
          <RandomizedLight radius={10} ambient={0.5} intensity={Math.PI} position={[2.5, 8, -2.5]} bias={0.001} />
        </AccumulativeShadows>
      </group>
      <OrbitControls minPolarAngle={0} maxPolarAngle={Math.PI / 2} autoRotate autoRotateSpeed={0.05} makeDefault />
      <Environment files="https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/dancing_hall_1k.hdr" background backgroundBlurriness={1} />
    </Canvas>
  )
}

export function GelatinousCube() {
  const { nodes: iceCubeNodes, materials: iceCubeMaterials } = useGLTF('/ice_cube.glb')
  const { nodes: calebNodes, materials: calebMaterials } = useGLTF('/caleb_2.glb')
  
  const frostTexture = useLoader(THREE.TextureLoader, '/frost_inverted.png')
  frostTexture.repeat.set(15, 15)
  frostTexture.wrapS = THREE.RepeatWrapping
  frostTexture.wrapT = THREE.RepeatWrapping

  const cubePosition = [0, -0.2, 0]
  const cubeColor = '#bbddff'

  return (
    <group dispose={null}>
      <mesh 
        geometry={iceCubeNodes.cube.geometry} 
        position={cubePosition}
        scale={2.5}
        >
        <MeshTransmissionMaterial 
          background={new THREE.Color(cubeColor)} 
          backside={false}
          samples={10}
          resolution={2048}
          transmission={1}
          thickness={1.5}
          ior={1.31}
          chromaticAberration={0.06}
          anisotropy={0.1}
          distortion={0.0}
          distortionScale={0.3}
          temporalDistortion={0.5}
          clearcoat={0.1}
          attenuationDistance={0.5}
          attenuationColor={'#ffffff'}
          color={cubeColor}
          bg={cubeColor}
          roughnessMap={frostTexture}
          roughness={0.8}
        />
      </mesh>
      <mesh
        castShadow
        renderOrder={-100}
        geometry={iceCubeNodes.cube_inverted.geometry}
        material-side={THREE.FrontSide}
        position={cubePosition}
        scale={2.5}
      />
      <group scale={2.7} position={[0, -0.4, 0]}>
        <mesh geometry={calebNodes.Mesh_0.geometry} material={calebMaterials.Material_0} />
      </group>
    </group>
  )
}
